import Link from 'next/link';
import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Card, { CardContent, CardMedia } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Features from '@/components/app/cart/components/list/item/features';
import Remove from '@/components/app/cart/components/list/item/remove';
import Props from '@/components/app/cart/components/list/item/type';
import PriceView from '@/components/shared/components/price-view';
import { toCamelCase } from '@/components/shared/helpers';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { cart: cartLocalization } = pageLevelLocalization;
export default function Item({
  data,
  className,
  isLastAddon,
  hideAddon,
  index,
  cartAddons,
  ...props
}: Props) {
  const {
    title,
    id,
    slug,
    addon,
    price,
    relatedAddonSlug,
    discount,
    lastPrice,
    saleEndedAt,
    icon,
    highlights,
    store,
    relatedAddonPrice,
  } = data;

  if (hideAddon && addon) {
    return null;
  }
  const itemPrice = () => {
    if (Array.isArray(addon) && addon.length > 0 && !hideAddon) {
      return {
        price: addon[0]?.price,
        lastPrice: addon[0]?.lastPrice,
        saleEndedAt: addon[0]?.saleEndedAt,
      };
    } else if (relatedAddonPrice?.length > 0 && hideAddon) {
      const addonsPrice = relatedAddonPrice?.reduce((total, item) => (total += item), 0);
      return {
        price: price + addonsPrice,
        lastPrice: lastPrice + addonsPrice,
        saleEndedAt: saleEndedAt,
      };
    } else {
      return {
        price: price,
        lastPrice: lastPrice,
        saleEndedAt: saleEndedAt,
      };
    }
  };

  const [showFeatures, setShowFeatures] = useState(false);
  return (
    <Card
      className={cn(
        'relative items-start justify-start overflow-visible rounded-none border-transparent bg-white px-[15px] py-[25px] md:pl-0',
        index != 0 && !addon && '!border-t-[1px] !border-t-[#E5E8EB]',
        !hideAddon &&
          relatedAddonSlug.length > 0 &&
          'z-20 after:absolute after:right-[90px] after:top-[105px] after:h-[25%] after:w-1 after:border-r-[1px] after:border-dashed after:border-r-[rgba(140,140,140,0.3)]',
        !hideAddon && showFeatures && relatedAddonSlug.length > 0 && 'after:h-[70%]',
        !hideAddon &&
          addon &&
          !isLastAddon &&
          'z-10 after:absolute after:right-[90px] after:top-[90px] after:h-[30%] after:w-1 after:border-r-[1px] after:border-dashed after:border-r-[rgba(140,140,140,0.3)]',
        className,
      )}
      noShadow
      {...props}
    >
      <Container className={cn('flex w-full', !(highlights?.length > 0) && 'items-center')}>
        <Container center className='h-[60px]'>
          <Remove product={data} hideModal={hideAddon} />
        </Container>
        {addon ? (
          <Container className='relative mr-3'>
            <CardMedia
              src={icon?.publicLink || imagesList.icons.settingIcon}
              width={30}
              height={30}
              alt={icon?.title}
              className='absolute -right-2 -top-2 rounded-lg'
            />
            <Container center className='h-12 w-12 rounded-[10px] bg-[#F2F3FF]'>
              <IoSettingsOutline color={ICON_COLOR.purple} />
            </Container>
          </Container>
        ) : (
          <CardMedia
            src={icon?.publicLink}
            width={60}
            height={60}
            alt={icon?.title}
            className='h-[66px] w-[66px] rounded-lg'
          />
        )}
        <CardContent className={cn('flex w-full flex-col py-0 pl-2 md:pl-0')}>
          <Container className='md:flex md:items-center md:justify-between'>
            <Link href={`/web/${slug}`} prefetch={false}>
              <Text size={SIZE_ENUM.MD} bold className='line-clamp-1 text-[#424244]'>
                {addon ? addon.title : title}
              </Text>
            </Link>
            {!hideAddon && (
              <PriceView
                price={itemPrice().price}
                priceWrapperClassName='hidden flex-row pr-4 items-center gap-1 md:flex'
                discount={discount}
                lastPrice={itemPrice().lastPrice}
                priceClassName='text-[19px]'
                lastPriceClassName='text-[15px] !mb-0 ml-1'
                saleEndedAt={itemPrice().saleEndedAt}
              />
            )}
          </Container>
          {hideAddon && cartAddons && cartAddons.length > 0 && (
            <Container
              center
              className='my-2 flex-wrap items-start justify-start gap-4 rounded-md bg-[#F9FAFC] px-[14px] py-[3px]'
            >
              {cartAddons?.map((item) => (
                <Container center className='min-w-fit gap-2' key={item}>
                  <FaCheck size={ICON_SIZE.xss} color={ICON_COLOR.purple} />
                  <Text size={SIZE_ENUM.XS} className='text-[#424244]'>
                    {/*{cartLocalization?.[toCamelCase(item)]}*/}
                    {item}
                  </Text>
                </Container>
              ))}
            </Container>
          )}
          <Container center className='justify-between'>
            <Text className='text-[13px] text-[#76767C]'>{store?.name}</Text>
            {!hideAddon && highlights?.length > 0 && (
              <Button
                className='hidden bg-white p-0 text-[13px] text-[#76767C] md:inline'
                onClick={() => setShowFeatures(!showFeatures)}
              >
                {localization.moreInfo}
                <IoIosArrowDown
                  color={ICON_COLOR.gray}
                  size={ICON_SIZE.sm}
                  className={cn('transition duration-300', showFeatures && 'rotate-180')}
                />
              </Button>
            )}

            <PriceView
              price={itemPrice().price}
              className={cn('flex md:hidden', hideAddon && 'md:flex')}
              priceWrapperClassName='flex flex-row pr-4 items-center gap-1'
              discount={discount}
              lastPrice={itemPrice().lastPrice}
              priceClassName='text-[19px]'
              lastPriceClassName='text-[15px] !mb-0 ml-1'
              saleEndedAt={itemPrice().saleEndedAt}
            />
          </Container>
          <Features data={highlights} visible={showFeatures && highlights?.length > 0} />
        </CardContent>
      </Container>
    </Card>
  );
}
