'use client';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi';

import cn from '@/lib/clsxm';

import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { CONTAINER_TAG_TYPE } from '@/components/@base/container/type';
import Cart from '@/components/layout/components/[default]/header/@shared/cart';
import Profile from '@/components/layout/components/[default]/header/@shared/profile';
import Search from '@/components/layout/components/[default]/header/@shared/search';
import HamburgerMenu from '@/components/layout/components/[default]/header/mobile/hamburger-menu';
import { getMenuListOptions } from '@/components/layout/services/hooks';
import ZhkLogo from '@/components/shared/components/logo';
import { LOGO_SIZE } from '@/components/shared/components/logo/type';

import { DATA_CY_HEADER, DATTA_CY_MENU_BUTTON } from '@/constant/cypress';

export default function Mobile() {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { data } = useSuspenseQuery(getMenuListOptions());
  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 10);
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  if (!data) {
    return null;
  }
  return (
    <Container
      data-cy={DATA_CY_HEADER}
      type={CONTAINER_TAG_TYPE.HEADER}
      center
      className={cn(
        'top-0 z-[60] justify-between px-4 pb-4 pt-6 md:hidden',
        scrolled && 'sticky bg-white pb-4 shadow-[0_5px_25px_0_rgba(255,107,1,0.1)]',
      )}
    >
      <Container className='flex items-center gap-5'>
        <Button
          data-cy={DATTA_CY_MENU_BUTTON}
          className='h-10 w-10 bg-white shadow-[0px_4px_8px_0px_rgba(153,126,86,0.08)]'
          onClick={toggleMenu}
        >
          <FiMenu color='#FF9606' size={20} width={0.25} />
        </Button>
        <ZhkLogo size={LOGO_SIZE.MD} darkMode />
      </Container>
      <Container className='flex items-center gap-2'>
        <Search isSmallScreen={true} />
        <Cart />
        <Profile />
      </Container>
      <HamburgerMenu showMenu={showMenu} menu={data?.header} toggleMenu={toggleMenu} />
    </Container>
  );
}
