import Link from 'next/link';

import DynamicIcon from '@/lib/dynamic-icon';
import { eventTracker } from '@/lib/service-workers';

import Container from '@/components/@base/container';
import List, { ListItem } from '@/components/@base/list';
import { Text } from '@/components/@base/typography';
import { useGetProfile } from '@/components/layout/services/hooks';

import { ICON_COLOR } from '@/constant/icon-size-color';
import cn from '@/lib/clsxm';

import Props from '@/components/layout/components/[default]/header/desktop/categories/dropdown/item/parent/type';

export default function ParentItem({ items, active, setActiveItemHandler }:Props) {
  const { profile } = useGetProfile();

  const menuEventHandler = (item) => {
    eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
      transcript: item.title,
    });
  };
  return (
    <Container className={cn('w-full max-w-[300px] rounded-[10px] bg-[#F9FAFC] p-[5px]}')}>
      <List>
        {items?.map((item, index) => {
          return (
            <ListItem
              onMouseEnter={() => {
                setActiveItemHandler(index);
              }}
              key={index}
              onClick={() => menuEventHandler(item)}
            >
              <Container
                center
                className='relative justify-start gap-3 rounded-md px-[19px] py-4 hover:bg-white hover:shadow-[0px_2px_8px_0px_rgba(73,75,84,0.05)]'
              >
                {index === active && (
                  <>
                    <Container className='absolute bottom-[26px] left-[-8px] h-5 w-5 -translate-x-1/2 translate-y-1/2 rotate-45 transform bg-[#F9FAFC]' />
                    <Container className='absolute bottom-[26px] left-[-12px] h-5 w-5 -translate-x-1/2 translate-y-1/2 rotate-45 transform bg-white' />
                  </>
                )}

                <DynamicIcon
                  iconName={item.icon.split('-')[1]}
                  color={index === active ? ICON_COLOR.orange : ICON_COLOR.gray}
                />
                <Text className='text-[15px] text-[#5B5C60]'>
                  <Link href={item.url} target={item.target} rel={item.rel} prefetch={false}>
                    {item.title}
                  </Link>
                </Text>
              </Container>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
}
