import { PopoverButton } from '@headlessui/react';

import cn from '@/lib/clsxm';

import Props from '@/components/@base/popup/button/type';

export default function Button({ children, className, ...props }: Props) {
  return (
    <PopoverButton className={cn('outline-none', className)} {...props}>
      {children}
    </PopoverButton>
  );
}
