'use client';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import { CONTAINER_TAG_TYPE } from '@/components/@base/container/type';
import Cart from '@/components/layout/components/[default]/header/@shared/cart';
import Profile from '@/components/layout/components/[default]/header/@shared/profile';
import Search from '@/components/layout/components/[default]/header/@shared/search';
import CascadingMenu from '@/components/layout/components/[default]/header/desktop/cascading-menu';
import Categories from '@/components/layout/components/[default]/header/desktop/categories';
import { getMenuListOptions } from '@/components/layout/services/hooks';
import ZhkLogo from '@/components/shared/components/logo';
import { LOGO_SIZE } from '@/components/shared/components/logo/type';

export default function Desktop() {
  const { data } = useSuspenseQuery(getMenuListOptions());
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }

      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setShowHeader(false);
      } else {
        // Scrolling up
        setShowHeader(true);
      }
      setLastScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Container
      className={cn(
        `top-0 z-50 hidden transition-transform duration-300 md:block`,
        showHeader ? 'translate-y-0' : '-translate-y-full',
        hasScrolled ? 'sticky bg-white shadow-sm' : 'shadow-none',
      )}
    >
      <Container
        type={CONTAINER_TAG_TYPE.HEADER}
        center
        className='relative mx-auto w-full max-w-[1279px] justify-between pb-[23px] pt-6'
      >
        <Container center className='gap-[30px]'>
          <ZhkLogo size={LOGO_SIZE.LG} darkMode />

          <Categories />
          {
            data.header.menu && <CascadingMenu items={data.header.menu} />
          }
        </Container>
        <Container center className='gap-[10px]'>
          <Search />
          <Cart />
          <Profile />
        </Container>
      </Container>
    </Container>
  );
}
