import { useState } from 'react';

import Container from '@/components/@base/container';
import ChildItem from '@/components/layout/components/[default]/header/desktop/categories/dropdown/item/child';
import ParentItem from '@/components/layout/components/[default]/header/desktop/categories/dropdown/item/parent';
import Props from '@/components/layout/components/[default]/header/desktop/categories/dropdown/type';
import { useGetMenuList } from '@/components/layout/services/hooks';
import { Banner } from '@/components/shared/components/cards';
import { BANNER_TYPE } from '@/components/shared/components/cards/banner/type';

import { DATTA_CY_MENU_ITEM_BUTTON } from '@/constant/cypress';

export default function Dropdown({ show }: Props) {
  const [activeItem, setActiveItem] = useState(0);
  const { data } = useGetMenuList();
  const setActiveItemHandler = (val) => {
    setActiveItem(val);
  };
  if (!data) {
    return null;
  }

  const adsBannerIdGenerator = (activeCategoryIndex: number, adsIndex: number) => {
    const alphabetMap = new Map([
      [0, 'a'],
      [1, 'b'],
      [2, 'c'],
      [3, 'd'],
    ]); // I suppose that the ads of each category to be displayed won't be more than 4, currently there should be just two of them
    return `cat${activeCategoryIndex}_ads_${alphabetMap.get(adsIndex) ?? adsIndex}`;
  };

  const { categories } = data?.header;

  return (
    <Container
      data-cy={DATTA_CY_MENU_ITEM_BUTTON}
      center
      className={`absolute left-0 top-20 z-50 w-full items-stretch justify-start rounded-[10px] bg-white p-[5px] opacity-0 shadow-[0px_20px_60px_-15px_rgba(0,0,0,0.15)] transition ease-in-out ${show ? 'visible opacity-100' : 'invisible'}`}
    >
      <ParentItem
        items={categories}
        active={activeItem}
        setActiveItemHandler={setActiveItemHandler}
      />
      <Container className='grid w-full grid-cols-3 p-[30px]'>
        <Container center className='col-span-2 items-start justify-between'>
          <ChildItem items={categories?.[activeItem]} parentsLength={categories.length} />
        </Container>
        <Container center className='col-span-1 flex-col items-end justify-between'>
          {categories?.[activeItem]?.banners?.slice(0, 2).map((item, index) => {
            return (
              <Banner
                key={adsBannerIdGenerator(activeItem, index)}
                id={adsBannerIdGenerator(activeItem, index)}
                className='max-h-[124px] justify-center rounded-[10px]'
                type={BANNER_TYPE.IMAGE}
                link={item.url}
                width={261}
                height={124}
                title={item.title}
                image={item?.image?.publicLink}
              />
            );
          })}
        </Container>
      </Container>
    </Container>
  );
}
