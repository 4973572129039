import { useQuery } from '@tanstack/react-query';

import { getSideIn } from '@/components/app/side-in/services';

import { GET_SIDE_IN } from '@/constant/query-key';

export function useGetSideIn() {
  return useQuery({
    queryKey: [GET_SIDE_IN],
    queryFn: getSideIn,
  });
}
