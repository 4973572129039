import Link from 'next/link';
import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import cn from '@/lib/clsxm';
import { eventTracker } from '@/lib/service-workers';

import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Dropdown from '@/components/layout/components/[default]/header/desktop/cascading-menu/dropdown';
import Props from '@/components/layout/components/[default]/header/desktop/cascading-menu/type';
import { useGetProfile } from '@/components/layout/services/hooks';

import { DATTA_CY_MENU_BUTTON } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';

export default function CascadingMenu({ items }: Props) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setOpenIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  const { profile } = useGetProfile();
  const menuEventHandler = (item) => {
    eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
      transcript: item.title,
    });
  };

  return (
    <Container center className='gap-[35px]'>
      {items?.map((item, index) => (
        <React.Fragment key={index}>
          {item.children.length > 0 ? (
            <>
              <Container
                data-cy={DATTA_CY_MENU_BUTTON}
                center
                className='p relative gap-[10px] after:absolute after:top-full after:h-10 after:w-full after:content-[""]'
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Text
                  className='text-[15px] text-[#424244] hover:text-[#ff9606]'
                  bold
                  onClick={() => menuEventHandler(item)}
                >
                  <Link href={item.url} prefetch={false} target={item.target} rel={item.rel}>
                    {item.title}
                  </Link>
                </Text>
                <IoIosArrowDown
                  className={cn(
                    'transition duration-300',
                    openIndex === index ? 'rotate-180' : 'rotate-0',
                  )}
                  color={ICON_COLOR.orange}
                  size={ICON_SIZE.xs}
                />
                <Dropdown show={openIndex === index} items={item.children} />
              </Container>
            </>
          ) : (
            <Container data-cy={DATTA_CY_MENU_BUTTON} center className='p relative gap-[10px]'>
              <Text
                className='text-[15px] text-[#424244] hover:text-[#ff9606]'
                bold
                onClick={() => menuEventHandler(item)}
              >
                <Link href={item.url} prefetch={false} target={item.target} rel={item.rel}>
                  {item.title}
                </Link>
              </Text>
            </Container>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
}
