'use client';
import Link from 'next/link';
import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Dropdown from '@/components/layout/components/[default]/header/desktop/categories/dropdown';

import { DATTA_CY_MENU_BUTTON } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import localization from '@/constant/localization';

export default function Categories() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Container
        data-cy={DATTA_CY_MENU_BUTTON}
        center
        className='p relative gap-[10px] border-l-[1px] border-solid border-l-[#8C8C8C21] pl-[30px] pr-[45px] after:absolute after:top-full after:h-10 after:w-full after:content-[""]'
      >
        <FaBars color={ICON_COLOR.orange} />
        <Text className='text-[15px] text-[#424244] hover:text-[#ff9606]' bold>
          <Link href='/search' prefetch={false}>{localization.categories}</Link>
        </Text>
        <IoIosArrowDown
          className={cn('transition duration-300', isOpen ? 'rotate-180' : 'rotate-0')}
          color={ICON_COLOR.orange}
          size={ICON_SIZE.xs}
        />
      </Container>
      <Dropdown show={isOpen} />
    </Container>
  );
}
