import Container from '@/components/@base/container';
import XImage from '@/components/@base/x-image';

import imagesList from '@/constant/images-list';
import localization from '@/constant/localization';

export default function Icon() {
  return (
    <Container center>
      <XImage
        src={imagesList.icons.contactAvatar1}
        alt={localization.zhaket}
        width={30}
        height={30}
        className='-translate-x-3 -translate-y-3 rounded-full border-2 border-solid border-white shadow-[0px_10px_25px_0px_rgba(0,0,0,0.07)]'
      />
      <XImage
        src={imagesList.icons.contactAvatar2}
        alt={localization.zhaket}
        width={36}
        height={36}
        className='rounded-full border-2 border-solid border-white shadow-[0px_10px_25px_0px_rgba(0,0,0,0.07)]'
      />
    </Container>
  );
}
