import { useEffect, useState } from 'react';
import { LuCopy } from 'react-icons/lu';
import { useCopyToClipboard } from 'usehooks-ts';

import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/app/side-in/components/banner-with-text/discount-box/type';

import localization from '@/constant/localization';

const RESET_BUTTON_STATE_TIMER = 5000;

export default function DiscountBox({ code }: Props) {
  const [, copy] = useCopyToClipboard();
  const [buttonTitle, setButtonTitle] = useState<string>(localization.copyDiscountCode);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (buttonTitle === localization.copied) {
      timer = setTimeout(() => {
        setButtonTitle(localization.copyDiscountCode);
      }, RESET_BUTTON_STATE_TIMER);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [buttonTitle]);

  const handleCopyButtonCLick = () => {
    copy(code)
      .then(() => {
        setButtonTitle(localization.copied);
      })
      .catch((error) => {
        console.error('Failed to copy!', error);
      });
  };

  return (
    <Container center className='w-full justify-between px-6'>
      <Text className='text-xs font-bold text-[#5B5C60]'>{localization.discountCode}</Text>
      <Container center className='h-[57px] rounded-[10px] bg-[#6097F314] p-2'>
        <Text className='px-5 py-[10px] text-sm text-[#5B5C60]'>{code}</Text>
        <Button
          variant={VARIANT_ENUM.OUTLINED}
          onClick={handleCopyButtonCLick}
          className='h-[39px] min-w-[87px] rounded-[7px] border-[#6097F3] bg-[#FFFFFF] text-[13px] font-bold text-[#6097F3] hover:bg-[#6097F3]'
          Icon={<LuCopy size={18} />}
        >
          {buttonTitle}
        </Button>
      </Container>
    </Container>
  );
}
