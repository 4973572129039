import Link from 'next/link';
import { IoClose } from 'react-icons/io5';
import {
  StylishAccordionButton,
  StylishAccordionPanel,
} from 'src/components/shared/components/stylish-disclosure';

import cn from '@/lib/clsxm';
import DynamicIcon from '@/lib/dynamic-icon';
import { eventTracker } from '@/lib/service-workers';

import Menu from '@/components/layout/components/[default]/header/mobile/hamburger-menu/menu'

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Accordion from '@/components/@base/accordion';
import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/layout/components/[default]/header/mobile/hamburger-menu/type';
import { useGetProfile } from '@/components/layout/services/hooks';
import ZhkLogo from '@/components/shared/components/logo';
import { LOGO_SIZE } from '@/components/shared/components/logo/type';

import { DATA_CY_MENU_CLOSE_BUTTON, DATA_CY_MENU_CONTAINER } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import React from 'react';

export default function HamburgerMenu({ showMenu, toggleMenu, menu }: Props) {
  const { profile } = useGetProfile();
  const menuEventHandler = (item) => {
    eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
      transcript: item.title,
    });
    toggleMenu(); //close menu after clicking on a menu item
  };
  return (
    <>
      <Container
        className={cn(
          'fixed left-0 top-0 z-20 h-full w-full bg-[#00000033] transition-opacity duration-300',
          showMenu ? 'opacity-100' : 'pointer-events-none opacity-0',
        )}
        onClick={toggleMenu}
      />
      <Container
        className={cn(
          'fixed right-0 top-0 z-30 h-full w-[370px] transform-gpu overflow-y-auto bg-white px-[10px] py-5 transition-transform duration-300 ease-in-out',
          showMenu ? 'translate-x-0' : 'translate-x-full',
        )}
      >
        <Container center className='justify-between rounded-md px-[20px]'>
          <ZhkLogo size={LOGO_SIZE.SM} darkMode />

          <Button
            data-cy={DATA_CY_MENU_CLOSE_BUTTON}
            className='h-[33px] w-[33px] bg-[#F7F8F9]'
            onClick={toggleMenu}
          >
            <IoClose color='#878F9B' size={ICON_SIZE.md} />
          </Button>
        </Container>
        <Container>
          <Container
            className='mt-6 flex w-full flex-col gap-[30px] rounded-[10px] bg-[#F9FAFC] p-2 px-[21px] py-[28px]'
            data-cy={DATA_CY_MENU_CONTAINER}
          >
            {menu?.categories?.map((item, index) => {
              return (
                <Accordion key={index}>
                  <StylishAccordionButton>
                    {({ open }) => (
                      <>
                        <DynamicIcon
                          iconName={item.icon.split('-')[1]}
                          color={open ? ICON_COLOR.orange : ICON_COLOR.gray}
                        />
                        <Text size={SIZE_ENUM.MD} className='text-[#5B5C60]' onClick={(e) => {
                          e.stopPropagation();
                          menuEventHandler(item)
                        }}>
                          <Link href={item.url} target={item.target} rel={item.rel}  prefetch={false}
                          >
                            {item.title}
                          </Link>
                        </Text>
                      </>
                    )}
                  </StylishAccordionButton>

                  <StylishAccordionPanel>
                    {item?.children?.map((item) => {
                      return item?.children?.map((child, index) => (
                        <Text
                          key={index}
                          size={SIZE_ENUM.SM}
                          className='text-[#76767C]'
                          onClick={() => menuEventHandler(child)}
                        >
                          <Link
                            href={child.url}
                            prefetch={false}
                            rel={item.rel}
                            target={item.target}
                          >
                            {child.title}
                          </Link>
                        </Text>
                      ));
                    })}
                  </StylishAccordionPanel>
                </Accordion>
              );
            })}
          </Container>

          {/*menu*/}
          <Menu menu={menu.menu} toggleMenu={toggleMenu} />

        </Container>
      </Container>
    </>
  );
}
