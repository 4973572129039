import { useQueryClient } from '@tanstack/react-query';

import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Container from '@/components/@base/container';
import { showToast } from '@/components/@base/toast/show-toast';
import Props from '@/components/app/cart/components/list/item/remove/actions/type';
import { useDeleteFromCart } from '@/components/app/cart/services/hooks';
import { useGetProfile } from '@/components/layout/services/hooks';

import useCommonModalStore from '@/store/common-modal-store';

import localization, { pageLevelLocalization } from '@/constant/localization';
import { GET_CART } from '@/constant/query-key';

const { cart: cartLocalization } = pageLevelLocalization;

export default function Actions({ product }: Props) {
  const { mutate, isPending } = useDeleteFromCart();
  const queryClient = useQueryClient();
  const { setShow } = useCommonModalStore();
  const { profile } = useGetProfile();
  const handleRemoveFromCart = () => {
    mutate(product.id, {
      onSuccess: ({ payload, messages }) => {
        eventTracker(profile?.mobile, 'remove_from_basket', new Date().toISOString(), {
          productId: product.id,
        });
        handleGA4Event(
          'remove_from_cart',
          [
            {
              item_id: product.id,
              item_name: product.title,
              price: product.title,
              quantity: 1,
              index: 0,
              affiliation: product?.store?.slug,
              discount: product.discount,
            },
          ],
          {
            currency: 'USD',
            value: product.price,
          },
        );

        queryClient.setQueryData([GET_CART], payload);
        setShow(false);
        showToast({ message: messages[0] });
      },
    });
  };
  return (
    <Container center className='w-full gap-[10px] pt-7'>
      <Button
        variant={VARIANT_ENUM.SOUL}
        onClick={() => setShow(false)}
        className='text-bold h-[52px] w-full text-[#878F9B]'
      >
        {localization.nope}
      </Button>
      <Button
        variant={VARIANT_ENUM.LIGHT_ORANGE}
        className='text-bold h-[52px] w-full bg-[#FF6437] text-white hover:bg-[#FFF1ED] hover:text-[#FF6437]'
        isLoading={isPending}
        onClick={handleRemoveFromCart}
      >
        {cartLocalization.yupRemoveIt}
      </Button>
    </Container>
  );
}
