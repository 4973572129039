import Link from 'next/link';

import { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import List, { ListItem } from '@/components/@base/list';
import { Text } from '@/components/@base/typography';
import { useGetProfile } from '@/components/layout/services/hooks';
import Props from '@/components/layout/components/[default]/header/desktop/cascading-menu/dropdown/item/child/type';

export default function ChildItem({ items,show }:Props) {

  const { profile } = useGetProfile();

  const menuEventHandler = (item) => {
    eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
      transcript: item.title,
    });
  };
    return(
      <Container className={`absolute right-full top-2 z-50  w-[270px] items-start justify-start rounded-[10px] bg-white p-[5px] opacity-0 shadow-[0px_20px_60px_-15px_rgba(0,0,0,0.15)] transition ease-in-out ${show ? 'visible opacity-100' : 'invisible'}`}>
               <List>

         {items.map((item, idx) => (
          <ListItem key={idx}>
          <Text
            size={SIZE_ENUM.MD}
            className='px-4 py-2 text-[#424244] hover:text-[#ff9606]'
            onClick={() => menuEventHandler(item)}
          >
            <Link href={item.url} prefetch={false} target={item.target} rel={item.rel}>
              {item.title}
            </Link>
          </Text>
          </ListItem>
         ))}
         </List>
      </Container>

  )
}
