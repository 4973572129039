import Link from 'next/link';
import React, { useState } from 'react';
import ChildItem from '@/components/layout/components/[default]/header/desktop/cascading-menu/dropdown/item/child';

import { eventTracker } from '@/lib/service-workers';
import { IoIosArrowBack } from 'react-icons/io';

import Container from '@/components/@base/container';
import List, { ListItem } from '@/components/@base/list';
import { Text } from '@/components/@base/typography';
import { useGetProfile } from '@/components/layout/services/hooks';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';

import Props from '@/components/layout/components/[default]/header/desktop/cascading-menu/dropdown/item/parent/type';

export default function ParentItem({ items }: Props) {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const handleMouseEnter = (index) => {
        setOpenIndex(index);
    };

    const handleMouseLeave = () => {
        setOpenIndex(null);
    };
    const { profile } = useGetProfile();

    const menuEventHandler = (item) => {
        eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
            transcript: item.title,
        });
    };
    return (
        <Container className='rounded-[10px] w-[300px]  p-[2px]'>
            <List>
                {items?.map((item, index) => {
                    return (
                        item.children.length > 0 ?
                            <ListItem
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                key={index}
                                onClick={() => menuEventHandler(item)}
                            >
                                <Container
                                    center
                                    className='relative justify-between items-center gap-6  px-[19px] py-4 hover:text-[#ff9606]'
                                >
                                    <Text className='text-[15px] text-[#5B5C60] hover:text-[#ff9606]'>
                                        <Link href={item.url} target={item.target} rel={item.rel}   prefetch={false}>
                                            {item.title}
                                        </Link>
                                    </Text>
                                    <IoIosArrowBack
                                        color={ICON_COLOR.orange}
                                        size={ICON_SIZE.xs}
                                    />
                                    <ChildItem items={item.children} show={openIndex === index} />
                                </Container>
                            </ListItem>
                            :
                            <ListItem
                                key={index}
                            >
                                <Text className='text-[15px] text-[#5B5C60] px-[19px] py-4 hover:text-[#ff9606]'>
                                    <Link href={item.url} target={item.target} rel={item.rel}  prefetch={false}>
                                        {item.title}
                                    </Link>
                                </Text>
                            </ListItem>
                    );
                })}
            </List>
        </Container>
    );
}
