import Link from 'next/link';
import {
  StylishAccordionButton,
  StylishAccordionPanel,
} from 'src/components/shared/components/stylish-disclosure';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Accordion from '@/components/@base/accordion';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/layout/components/[default]/header/mobile/hamburger-menu/menu/type';
import React from 'react';

import { useGetProfile } from '@/components/layout/services/hooks';
import { eventTracker } from '@/lib/service-workers';

export default function Menu({ menu, toggleMenu }: Props) {
  const { profile } = useGetProfile();
  const menuEventHandler = (item) => {
    eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
      transcript: item.title,
    });
    toggleMenu(); //close menu after clicking on a menu item
  };
  return (
    <Container center className='flex-col items-start gap-5 p-[20px]'>
      {menu.map((item, index) => {
        return item.children.length > 0 ? (
          <Accordion key={index}>
            <Container className=' flex w-full items-center justify-center'>
              <Text
                size={SIZE_ENUM.MD}
                className='w-full text-[#5B5C60]'
                onClick={() => menuEventHandler(item)}
              >
                <Link href={item.url} target={item.target} rel={item.rel} prefetch={false}>
                  {item.title}
                </Link>
              </Text>
              <StylishAccordionButton>{({ open }) => <></>}</StylishAccordionButton>
            </Container>
            <StylishAccordionPanel className='w-full bg-[#F7F8F9] shadow-none'>
              {item?.children?.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    {item.children.length > 0 ? (
                      <Accordion key={index}>
                        <Container className=' flex w-full items-center justify-center'>
                          <Text
                            size={SIZE_ENUM.MD}
                            className='w-full text-[#5B5C60]'
                            onClick={() => {
                              menuEventHandler(item);
                            }}
                          >
                            <Link
                              href={item.url}
                              target={item.target}
                              rel={item.rel}
                              prefetch={false}
                            >
                              {item.title}
                            </Link>
                          </Text>
                          <StylishAccordionButton>
                            {({ open }) => <></>}
                          </StylishAccordionButton>
                        </Container>
                        <StylishAccordionPanel className='w-full bg-[#F7F8F9] pt-0 shadow-none'>
                          {item.children?.map((child, index) => (
                            <Text
                              key={index}
                              size={SIZE_ENUM.SM}
                              className='text-[#76767C] '
                              onClick={() => menuEventHandler(item)}
                            >
                              <Link
                                href={child.url}
                                prefetch={false}
                                rel={child.rel}
                                target={child.target}
                              >
                                {child.title}
                              </Link>
                            </Text>
                          ))}
                        </StylishAccordionPanel>
                      </Accordion>
                    ) : (
                      <Text
                        key={index}
                        size={SIZE_ENUM.MD}
                        className='text-[#5B5C60]'
                        onClick={() => menuEventHandler(item)}
                      >
                        <Link
                          href={item.url}
                          prefetch={false}
                          rel={item.rel}
                          target={item.target}
                        >
                          {item.title}
                        </Link>
                      </Text>
                    )}
                  </React.Fragment>
                );
              })}
            </StylishAccordionPanel>
          </Accordion>
        ) : (
          <Text
            key={index}
            size={SIZE_ENUM.MD}
            className='text-[#5B5C60]'
            onClick={() => menuEventHandler(item)}
          >
            <Link href={item.url} prefetch={false} rel={item.rel} target={item.target}>
              {item.title}
            </Link>
          </Text>
        );
      })}
    </Container>
  );
}
