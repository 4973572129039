import { DisclosurePanel, Transition } from '@headlessui/react';

import Props from '@/components/@base/accordion/panel/type';

export default function Panel({ children, ...props }: Props) {
  return (
    <Transition
      enter='transition duration-100 ease-out'
      enterFrom='transform scale-95 opacity-0'
      enterTo='transform scale-100 opacity-100'
      leave='transition duration-75 ease-out'
      leaveFrom='transform scale-100 opacity-100'
      leaveTo='transform scale-95 opacity-0'
    >
      <DisclosurePanel {...props}>{children}</DisclosurePanel>
    </Transition>
  );
}
