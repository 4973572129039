import Link from 'next/link';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import ZhkLogo from '@/components/shared/components/logo';
import { LOGO_SIZE } from '@/components/shared/components/logo/type';
import Props from '@/components/layout/components/[default]/footer/menu/desktop/type';

export default function Desktop({ menu }: Props) {
  return (
    <Container className='hidden w-[calc(100%_-_377px)] pt-[25px] md:grid md:grid-cols-3'>
      {menu?.map((item, index) => {
        return (
          <Container
            center
            className='w-full flex-col items-start justify-start gap-4'
            key={index}
          >
            {index !== 0 ? (
              <Text size={SIZE_ENUM.MD}>{item.title}</Text>
            ) : (
              <ZhkLogo size={LOGO_SIZE.SM} darkMode />
            )}
            <Container center className='flex-col items-start gap-2'>
              {item?.children?.map((item, index) => (
                <Text size={SIZE_ENUM.SM} className='text-[#76767C]' key={index}>
                  <Link href={item.url} prefetch={false} rel={item.rel} target={item.target}>
                    {item.title}
                  </Link>
                </Text>
              ))}
            </Container>
          </Container>
        );
      })}
    </Container>
  );
}
