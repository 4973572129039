import Link from 'next/link';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Icon from '@/components/layout/components/[default]/footer/contact/icon';

import { DATA_CY_FOOTER_CONTACT } from '@/constant/cypress';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;
export default function Contact() {
  return (
    <Container data-cy={DATA_CY_FOOTER_CONTACT} center className='w-full justify-between pt-7'>
      <Container center className='gap-3'>
        <Icon />
        <Container>
          <Text size={SIZE_ENUM.MD} className='text-[#424244]'>
            {layoutLocalization.askIfYouHaveAnyQuestion}
          </Text>
          <Text size={SIZE_ENUM.SM} className='text-[#878F9B]'>
            {layoutLocalization.registerThenSendTicket}
          </Text>
        </Container>
      </Container>
      <Link href='/dashboard/tickets/new' prefetch={false}>
        <Button className='h-[40px] w-[70px] bg-[#F0F8FF]'>
          <Text size={SIZE_ENUM.SM} className='text-[#6097F3]'>
            {localization.message}
          </Text>
        </Button>
      </Link>
    </Container>
  );
}
