'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { RiDownload2Line } from 'react-icons/ri';
import { RxPerson } from 'react-icons/rx';
import Skeleton from 'react-loading-skeleton';

import cn from '@/lib/clsxm';
import { getCookie, setCookie } from '@/lib/cookies';
import { isGuestUser, priceFormatter } from '@/lib/helper';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import Popup, { PopupButton, PopupPanel } from '@/components/@base/popup';
import { Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import XImage from '@/components/@base/x-image';
import CircularProgressBar from '@/components/layout/components/[default]/header/@shared/profile/progressbar';
import { useGetProfile } from '@/components/layout/services/hooks';

import ICON_SIZE from '@/constant/icon-size-color';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;

const GuestProfileLink: React.FC<{ afterLoginQuery: string }> = React.memo(
  ({ afterLoginQuery }) => (
    <Link
      href={`/login${afterLoginQuery}`}
      className='group flex h-10 w-fit min-w-12 items-center justify-center rounded-lg bg-white p-2 shadow-[0px_4px_8px_0px_rgba(153,126,86,0.08)] outline-none transition duration-300 hover:bg-[#76767c] md:h-12 md:bg-[#F9FAFC] md:shadow-none'
      passHref
    >
      <RxPerson
        size={ICON_SIZE.sm}
        className='text-[#878F9B] transition duration-300 group-hover:text-[#f7f8f9]'
        aria-hidden='true'
      />
      <Text
        type={TEXT_TYPE.SPAN}
        className='pr-1 text-[#787676] transition duration-300 group-hover:text-[#f7f8f9]'
      >
        {layoutLocalization.loginOrRegister}
      </Text>
    </Link>
  ),
);

const LoadingProfile = React.memo(() => (
  <Container center className='h-10 w-10 items-end md:h-12 md:w-12'>
    <Skeleton width={48} className='h-10 rounded-lg md:h-12' />
  </Container>
));

const AuthenticatedProfilePopup: React.FC<{
  profile: any;
  logout: () => void;
}> = React.memo(({ profile, logout }) => {
  const hasProfileProgress = profile?.progress && profile.progress < 100;
  const dashboardPath = (profile?.newDashboard ?? false) ? '/panel': '/dashboard';

  return (
    <Popup>
      <PopupButton
        className={cn(
          'group flex h-10 w-fit min-w-12 items-center justify-center rounded-lg bg-white shadow-[0px_4px_8px_0px_rgba(153,126,86,0.08)] outline-none md:h-12 md:bg-[#F9FAFC] md:shadow-none',
          !profile && 'p-2 transition duration-300 hover:bg-[#76767c]',
        )}
        aria-label='User Profile'
      >
        {profile?.avatar ? (
          <XImage
            src={profile.avatar.publicLink}
            alt={profile.avatar.title}
            className='h-10 w-10 rounded-lg md:h-12 md:w-12'
            width={48}
            height={48}
          />
        ) : (
          <RxPerson size={ICON_SIZE.sm} className='text-[#878F9B]' aria-hidden='true' />
        )}
      </PopupButton>

      <PopupPanel
        anchor='bottom end'
        className='relative z-50 mt-3 w-[251px] overflow-visible rounded-lg bg-white px-1 pb-1 pt-8 shadow-[15px_0px_30px_rgba(150,155,164,0.2)]'
      >
        <Container
          className='absolute -top-3 left-5 h-0 w-0 border-b-[12px] border-l-[12px] border-r-[12px] border-b-white border-l-transparent border-r-transparent'
          aria-hidden='true'
        />
        <Container>
          <Container center className='flex-col items-start pr-7'>
            <Text className='text-[#5B5C60]' size={SIZE_ENUM.MD}>
              {profile.publicName}
            </Text>
            {profile.balance?.total > 0 && (
              <Text className='text-[#878F9B]'>
                {priceFormatter(profile.balance.total)} {localization.IRCurrency}
              </Text>
            )}
          </Container>

          <Link href={dashboardPath} prefetch={false}>
            <Container center className='justify-start gap-4 py-4 pr-7 hover:bg-[#F9FAFC]'>
              <XImage
                src={imagesList.icons.dashboardIcon}
                alt={localization.zhaket}
                width={14}
                height={14}
              />
              <Text className='text-[#5B5C60]'>{layoutLocalization.dashboard}</Text>
            </Container>
          </Link>

          {hasProfileProgress && (
            <Link href={`${dashboardPath}/settings/edit-profile`} prefetch={false}>
              <Container
                center
                className='justify-between gap-4 rounded-xl bg-[#FFF5E6] py-4 pl-3 pr-7 hover:bg-[#F9FAFC]'
              >
                <Container center className='gap-4'>
                  <XImage
                    src={imagesList.icons.orangeProfile}
                    alt={localization.zhaket}
                    width={12}
                    height={16}
                  />
                  <Text className='text-[#EB8800]'>
                    {layoutLocalization.profileCompletion}
                  </Text>
                </Container>
                {profile.progress !== undefined && (
                  <CircularProgressBar percentage={profile.progress} />
                )}
              </Container>
            </Link>
          )}

          <Link href={`${dashboardPath}/downloads`}>
            <Container center className='justify-start gap-4 py-4 pr-7 hover:bg-[#F9FAFC]'>
              <RiDownload2Line size={15} color='#5B5C60' aria-hidden='true' />
              <Text className='text-[#5B5C60]'>{layoutLocalization.downloads}</Text>
            </Container>
          </Link>

          <Link href={`${dashboardPath}/tickets/new`} prefetch={false}>
            <Container center className='justify-start gap-4 py-4 pr-7 hover:bg-[#F9FAFC]'>
              <XImage
                src={imagesList.icons.addBlue}
                alt={localization.zhaket}
                width={15}
                height={15}
              />
              <Text className='text-[#6097F3]'>{layoutLocalization.sendTicket}</Text>
            </Container>
          </Link>

          <Link href={`${dashboardPath}/settings/edit-profile`} prefetch={false}>
            <Container center className='justify-start gap-4 py-4 pr-7 hover:bg-[#F9FAFC]'>
              <XImage
                src={imagesList.icons.settingIcon}
                alt={localization.zhaket}
                width={17}
                height={17}
              />
              <Text className='text-[#5B5C60]'>{layoutLocalization.editProfile}</Text>
            </Container>
          </Link>

          <Container
            onClick={logout}
            center
            className='cursor-pointer justify-start gap-4 py-4 pr-7 hover:bg-[#F9FAFC]'
            role='button'
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') logout();
            }}
          >
            <XImage
              src={imagesList.icons.logoutIcon}
              alt={localization.zhaket}
              width={19}
              height={19}
            />
            <Text className='text-[#5B5C60]'>{layoutLocalization.logOut}</Text>
          </Container>
        </Container>
      </PopupPanel>
    </Popup>
  );
});

const Profile = () => {
  const { profile, isLoading, isAuthenticated, logout } = useGetProfile();
  const pathname = usePathname();

  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [afterLoginQuery, setAfterLoginQuery] = useState<string>('');

  // Set guest status
  useEffect(() => {
    setIsGuest(isGuestUser());
  }, []);

  // Handle mounting and after login query
  useEffect(() => {
    setHasMounted(true);
    if (typeof window !== 'undefined') {
      setAfterLoginQuery(`?afterLogin=${encodeURIComponent(pathname)}`);
    }
  }, [pathname]);

  //zebline
  useEffect(() => {
    if (hasMounted && !getCookie('zebline-login') && profile) {
      const timer = setTimeout(() => {
        setCookie('zebline-login', profile.mobile);
        window?.zebline?.user?.login(profile.mobile);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [profile, hasMounted]);
  if ((isGuest || !isAuthenticated )&& !isLoading) {
    return <GuestProfileLink afterLoginQuery={afterLoginQuery} />;
  }

  if (isLoading) {
    return <LoadingProfile />;
  }

  if (isAuthenticated && profile) {
    return <AuthenticatedProfilePopup profile={profile} logout={logout} />;
  }

  return null;
};

export default Profile;
