import { usePathname, useRouter } from 'next/navigation';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { TbShoppingCart } from 'react-icons/tb';

import { useClientSmallScreen } from '@/lib/user-agent/client';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import Popup, { PopupButton, PopupPanel } from '@/components/@base/popup';
import { Text } from '@/components/@base/typography';
import Item from '@/components/app/cart/components/list/item';
import Loading from '@/components/app/cart/components/list/item/loading';
import { useGetCart } from '@/components/app/cart/services/hooks';
import PriceView from '@/components/shared/components/price-view';
import { loadingGenerator } from '@/components/shared/helpers';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import localization, { pageLevelLocalization } from '@/constant/localization';
import WalletModel from '@/models/wallet.model';

const { cart: cartLocalization, product: productLocalization } = pageLevelLocalization;
export default function Cart() {
  const { data, isRefetching, refetch } = useGetCart();

  const router = useRouter();
  const pathname = usePathname();
  const isSmallScreen = useClientSmallScreen();
  const showPanel = !(isSmallScreen || pathname === '/cart');
  const productsLength = data?.products?.reduce(
    (total, item) => (!item.addon ? (total += 1) : (total += 0)),
    0,
  );
  const calculateFinalPrice = () => {
    if (!data) return 0;

    const { total = 0, subtotal = 0, wallet = {} as WalletModel } = data;
    const finalPrice = total - subtotal;
    return finalPrice < 0 ? 0 : finalPrice;
  };
  const groupedProducts = data?.products?.reduce((acc, { product_id, addon }) => {
    if (!addon) return acc;
    let existingProduct = acc.find((p) => p.product_id === product_id);

    if (existingProduct) {
      existingProduct.addons.push(addon.title);
    } else {
      acc.push({ product_id: product_id, addons: [addon.title] });
    }

    return acc;
  }, []);

  return (
    <Popup>
      <PopupButton
        onClick={() => {
          if (showPanel) refetch();
          else router.push('/cart');
        }}
        className='group relative flex h-10 w-10 items-center justify-center rounded-lg border-0 bg-white shadow-[0px_4px_8px_0px_rgba(153,126,86,0.08)] transition duration-300 hover:bg-[#FFF5E6] ui-open:bg-[#FFF5E6] md:h-12 md:w-12 md:bg-[#F9FAFC] md:shadow-none'
      >
        <TbShoppingCart
          className='text-[#878F9B] transition duration-300 group-hover:text-[#FF9606] ui-open:text-[#FF9606]'
          size={ICON_SIZE.md}
        />
        {data && data?.products?.length > 0 && (
          <Container
            center
            className='text-bold absolute -right-[2px] -top-[2px] h-[15px] w-[15px] rounded-full border-[1px] border-white bg-[#FF473B] pt-1 text-xs text-white'
          >
            {productsLength}
          </Container>
        )}
      </PopupButton>

      {showPanel && (
        <PopupPanel
          anchor='bottom end'
          className='relative z-50 ml-[-60px] mt-3 w-[412px] rounded-md bg-[#FFFFFF] pt-7 shadow-[15px_0px_30px_rgba(150,155,164,0.2)]'
        >
          <Container className='overflow-hidden'>
            <Container center className='justify-start gap-[10px] px-[15px] pt-[15px]'>
              <TbShoppingCart color={ICON_COLOR.gray} size={ICON_SIZE.md} />
              <Text className='text-[17px] text-[#454545]' bold>
                {localization.cart}
              </Text>
            </Container>
            <>
              <Container className='custom-scrollbar h-full max-h-[370px] min-h-[150px] overflow-y-auto p-[15px]'>
                {isRefetching ? (
                  loadingGenerator(2, () => <Loading />)
                ) : data?.products?.length === 0 ? (
                  <Text size={SIZE_ENUM.LG} bold center className='text-[#424244]'>
                    {cartLocalization.cartIsEmpty}
                  </Text>
                ) : (
                  data?.products?.map((item, index) => {
                    const matchedProduct = groupedProducts.find(
                      (p) => p.product_id === item.product_id,
                    );
                    return (
                      <Item
                        data={item}
                        key={item.id}
                        index={index}
                        className='px-0'
                        hideAddon
                        cartAddons={matchedProduct ? matchedProduct.addons : []}
                      />
                    );
                  })
                )}
              </Container>
              {data?.products?.length !== 0 && !isRefetching && (
                <Container center className='p-[15px]'>
                  <Container center className='w-full justify-between bg-[#F9FAFC] p-[15px]'>
                    <Container>
                      <Text className='text-[#878F9B]' size={SIZE_ENUM.XS}>
                        {productLocalization.payable}
                      </Text>
                      <PriceView priceClassName='text-[19px]' price={calculateFinalPrice()} />
                    </Container>
                    <a href='/cart'>
                      <Button className='h-[59px] w-[159px] bg-[#81CF33] text-base text-[#F9FAFC] shadow-[0px_5px_25px_0px_rgba(52,168,85,0.17)] hover:bg-[#F5FBED] hover:text-[#81CF33]'>
                        {cartLocalization.submitAndPay}
                        <FaArrowLeftLong size={ICON_SIZE.md} className='pr-2' />
                      </Button>
                    </a>
                  </Container>
                </Container>
              )}
            </>
          </Container>
        </PopupPanel>
      )}
    </Popup>
  );
}
