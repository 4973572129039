'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import cn from '@/lib/clsxm';
import { getCookie, setCookie } from '@/lib/cookies';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import { VARIANT_ENUM } from '@/components/@base/button/type';
import Card, { CardAction, CardContent, CardHeader } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { showToast } from '@/components/@base/toast/show-toast';
import { Text } from '@/components/@base/typography';
import { usePostRate } from '@/components/app/product/services/hooks';
import Item from '@/components/layout/components/[default]/footer/rating/item';
import { useGetProductRating, useGetProfile } from '@/components/layout/services/hooks';

import localization, { pageLevelLocalization } from '@/constant/localization';
import InvoiceModel from '@/models/invoice.model';

const { product: productLocalization } = pageLevelLocalization;

export default function Rating() {
  const { data: ratingData, refetch } = useGetProductRating();

  const { mutate: postRateMutate, isPending } = usePostRate();

  const { profile } = useGetProfile();
  const profileId = profile?.id;

  const [displayCard, setDisplayCard] = useState(false);

  const [selectedRates, setSelectedRates] = useState<{ id: string; value: number }[]>([]);

  const cookieKey = `product_rate_${profileId}`;
  const cookieValue = getCookie(cookieKey);

  useEffect(() => {
    if (profileId && cookieValue !== 'showed') {
      refetch().then((res) => {
        if (res?.data?.length > 0) {
          setDisplayCard(true);
          // Set cookie for 24 hours so we won't show again immediately
          setCookie(cookieKey, 'showed', { maxAge: 86400 });
        }
      });
    }
  }, [profileId, cookieValue, refetch]);

  const onRatesChange = useCallback((id: string, value: number) => {
    setSelectedRates((prev) => {
      const existingIdx = prev.findIndex((item) => item.id === id);
      if (existingIdx >= 0) {
        const updated = [...prev];
        updated[existingIdx] = { id, value };
        return updated;
      }
      return [...prev, { id, value }];
    });
  }, []);

  const handleSave = useCallback(() => {
    if (selectedRates.length === 0) {
      setDisplayCard(false);
      return;
    }

    selectedRates.forEach((item, index) => {
      postRateMutate(
        { product_id: item.id, point: item.value },
        {
          onSuccess: () => {
            if (index + 1 === selectedRates.length) {
              setDisplayCard(false);
              showToast({
                message: productLocalization.rateSavedSuccessfully,
                type: 'success',
              });
            }
          },
          onError: () => {
            if (index + 1 === selectedRates.length) {
              showToast({ message: localization.somethingWentWrong, type: 'error' });
            }
          },
        },
      );
    });
  }, [selectedRates, postRateMutate]);

  return (
    <Card
      noBorder
      className={cn(
        'fixed bottom-4 left-4 z-[9999999999] !w-[370px] rounded-md bg-white p-1 shadow-[0px_20px_60px_-15px_rgba(0,0,0,0.15)]',
        'transform transition-transform duration-300 ease-in-out',
        displayCard ? 'translate-x-0' : '-translate-x-[120%]',
      )}
    >
      <CardHeader className='flex justify-between rounded-md bg-[linear-gradient(180deg,#6097f324_0%,rgba(96,151,243,0)_100%)] p-4'>
        <Container>
          <Text className='text-[15px] text-[#424244]' bold>
            {ratingData?.length === 1
              ? localization.rateToYourPurchase
              : localization.rateToYourPurchases}
          </Text>
          <Text size={SIZE_ENUM.XS} className='text-[#76767C]'>
            {localization.yourRateIsUseful}
          </Text>
        </Container>

        <Button
          variant={VARIANT_ENUM.SOUL}
          className='group h-10 w-10 border-none bg-white p-0 shadow-none'
          onClick={() => setDisplayCard(false)}
        >
          <AiOutlineClose
            size={22}
            className='text-gray-100 transition duration-300 group-hover:text-white'
          />
        </Button>
      </CardHeader>

      <CardContent>
        {ratingData?.map((item: InvoiceModel) => (
          <Item data={item} key={item.invoiceId} onChange={onRatesChange} />
        ))}
      </CardContent>

      <CardAction className='flex items-center gap-2 pt-0'>
        <Button
          className='w-[84px] text-[#76767C]'
          variant={VARIANT_ENUM.SOUL}
          onClick={() => setDisplayCard(false)}
        >
          {localization.laterPlease}
        </Button>

        <Button
          onClick={handleSave}
          className='w-[84px] bg-[#6097F3] text-white hover:bg-[#F0F8FF] hover:text-[#6097F3]'
          isLoading={isPending}
        >
          {localization.giveRate}
        </Button>
      </CardAction>
    </Card>
  );
}
