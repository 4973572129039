import Skeleton from 'react-loading-skeleton';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';

export default function Loading() {
  return (
    <Container
      center
      className={cn('mb-2 justify-start gap-4 px-[15px] pb-[15px] pt-[25px] md:pl-0')}
    >
      <Skeleton width={60} height={60} />
      <Container className='flex w-full flex-col gap-2'>
        <Skeleton width='100%' height={20} />
        <Container center className='w-full justify-between'>
          <Skeleton width={65} height={20} />
          <Skeleton width={65} height={20} />
        </Container>
      </Container>
    </Container>
  );
}
