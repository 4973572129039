'use client';
import React, { useState } from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';

import Container from '@/components/@base/container';
import Tooltip from '@/components/@base/tooltip';

const Rating = ({
  onSelect,
  totalStars = 5,
  initialValue = 0,
  fillColor = '#FEA000',
  emptyColor = '#FEA000',
  size = 'text-2xl',
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [isTouch, setIsTouch] = useState(false);
  const stars = Array(totalStars).fill(0);

  const handleClick = (value) => {
    setCurrentValue(value);
    if (onSelect) onSelect(value);
  };

  const handleMouseOver = (newHoverValue) => {
    if (!isTouch) {
      setHoverValue(newHoverValue);
    }
  };

  const handleMouseMove = (e, index) => {
    calculateValueFromMouse(e, index);
  };

  const handleMouseLeave = () => {
    if (!isTouch) {
      setHoverValue(undefined);
    }
  };

  const calculateValueFromMouse = (e, index) => {
    const { width, left } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const fullValue = index + 1;
    const halfValue = index + 0.5;

    if (x < width / 2) {
      handleMouseOver(halfValue);
    } else {
      handleMouseOver(fullValue);
    }
  };

  const calculateValueFromTouch = (e, index) => {
    const touch = e.touches[0];
    const target = e.currentTarget;
    const { width, left } = target.getBoundingClientRect();
    const x = touch.clientX - left;
    const halfValue = index + 0.5;
    const fullValue = index + 1;

    if (x < width / 2) {
      setHoverValue(halfValue);
    } else {
      setHoverValue(fullValue);
    }
  };

  const handleTouchStart = (e, index) => {
    setIsTouch(true);
    calculateValueFromTouch(e, index);
  };

  const handleTouchMove = (e, index) => {
    calculateValueFromTouch(e, index);
  };

  const handleTouchEnd = () => {
    setCurrentValue(hoverValue || currentValue);
    if (onSelect) onSelect(hoverValue || currentValue);
    setHoverValue(undefined);
    setIsTouch(false);
  };

  const getIcon = (index) => {
    const value = hoverValue !== undefined ? hoverValue : currentValue;
    if (value >= index + 1) {
      return <FaStar fill={fillColor} />;
    } else if (value >= index + 0.5) {
      return <FaStarHalfAlt fill={fillColor} />;
    } else {
      return <FaRegStar fill={emptyColor} />;
    }
  };

  return (
    <Container dir='ltr' className={`flex ${size}`} onMouseLeave={handleMouseLeave}>
      {stars.map((_, index) => {
        const halfValue = index + 0.5;
        const tooltipContent = `${hoverValue !== undefined ? hoverValue : currentValue}`;

        return (
          <Container
            key={index}
            className='cursor-pointer'
            data-tooltip-id='ratingTooltip'
            data-tooltip-content={tooltipContent}
            onMouseMove={(e) => handleMouseMove(e, index)}
            onClick={() => handleClick(hoverValue || currentValue)}
            onTouchStart={(e) => handleTouchStart(e, index)}
            onTouchMove={(e) => handleTouchMove(e, index)}
            onTouchEnd={handleTouchEnd}
            role='button'
            aria-label={`Rate ${halfValue} stars`}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleClick(hoverValue || currentValue);
            }}
          >
            {getIcon(index)}
          </Container>
        );
      })}
      <Tooltip id='ratingTooltip' place='top' className='!text-[15px] !font-medium' />
    </Container>
  );
};

export default Rating;
