'use client';
import React, { useEffect, useRef, useState } from 'react';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';

import { DATA_CY_FOOTER_ABOUT_US } from '@/constant/cypress';
import localization, { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;

export default function AboutUs() {
  const enamadLogoUrl =
    'https://trustseal.enamad.ir/logo.aspx?id=53151&Code=hsRG8VHtoayAfhStqcfA';
  const enamadLink = 'https://trustseal.enamad.ir/?id=53151&Code=hsRG8VHtoayAfhStqcfA';
  const defaultImageUrl = '/images/logo/e-namad.svg';

  const [displayImage, setDisplayImage] = useState<string | null>(null);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const imageRef = useRef(null);
  useEffect(() => {
    const timeoutDuration = 2000; // 2 seconds
    const timeoutId = setTimeout(() => {
      setHasTimedOut(true);
      setDisplayImage(defaultImageUrl);
    }, timeoutDuration);

    const img = new Image();

    img.onload = () => {
      clearTimeout(timeoutId);
      setDisplayImage(enamadLogoUrl);
    };

    img.onerror = () => {
      clearTimeout(timeoutId);
      setDisplayImage(defaultImageUrl);
    };

    img.src = enamadLogoUrl;

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Container data-cy={DATA_CY_FOOTER_ABOUT_US} center className='pt-10'>
      <Container className='items-center justify-between gap-[50px] rounded-lg bg-[#F9FAFC] p-[23px] text-[#5D6877] md:flex'>
        <Text medium size={SIZE_ENUM.SM} className='text-[#5D6877] md:hidden'>
          {layoutLocalization.aboutUs}
        </Text>
        <Container center className='justify-between gap-[30px] pt-[10px] md:hidden'>
          <Text medium size={SIZE_ENUM.SM} className='text-[#5D6877]'>
            {layoutLocalization.aboutUs2}
          </Text>
          <Container
            center
            className='min-w-[82px] rounded-md bg-white p-[10px] shadow-[0px_5px_15px_0px_rgba(73,75,84,0.06)]'
          >
            <a
              referrerPolicy='origin'
              target='_blank'
              href={enamadLink}
              style={{ cursor: 'pointer' }}
            >
              {displayImage && (
                <img
                  ref={imageRef}
                  src={displayImage}
                  alt={localization.enamad}
                  style={{ cursor: 'pointer' }}
                  id='hsRG8VHtoayAfhStqcfA'
                  className='h-[71px] w-[58px]'
                />
              )}
              {hasTimedOut && !displayImage && (
                <img
                  src={defaultImageUrl}
                  alt={localization.enamad}
                  style={{ cursor: 'pointer' }}
                  id='hsRG8VHtoayAfhStqcfA'
                  className='h-[71px] w-[58px]'
                />
              )}
            </a>
          </Container>
        </Container>
        <Text medium size={SIZE_ENUM.SM} className='hidden text-[#5D6877] md:block'>
          {layoutLocalization.aboutUs}
          {layoutLocalization.aboutUs2}
        </Text>
        <a
          referrerPolicy='origin'
          target='_blank'
          href={enamadLink}
          style={{ cursor: 'pointer' }}
        >
          {displayImage && (
            <img
              ref={imageRef}
              src={displayImage}
              alt={localization.enamad}
              style={{ cursor: 'pointer' }}
              id='hsRG8VHtoayAfhStqcfA'
              className='hidden min-w-[67px] md:block'
            />
          )}
          {hasTimedOut && !displayImage && (
            <img
              src={defaultImageUrl}
              alt={localization.enamad}
              style={{ cursor: 'pointer' }}
              id='hsRG8VHtoayAfhStqcfA'
              className='hidden min-w-[67px] md:block'
            />
          )}
        </a>
      </Container>
    </Container>
  );
}
