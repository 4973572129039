import { Popover } from '@headlessui/react';

import Button from '@/components/@base/popup/button/index';
import Panel from '@/components/@base/popup/panel/index';
import Props from '@/components/@base/popup/type';

export default function Popup({ children, ...props }: Props) {
  return <Popover {...props}>{children}</Popover>;
}

export {
  Button as PopupButton,
  Panel as PopupPanel
};
