'use client';
import Link from 'next/link';
import { IoIosArrowDown } from 'react-icons/io';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Accordion, { AccordionButton, AccordionPanel } from '@/components/@base/accordion';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/layout/components/[default]/footer/menu/mobile/collapse/type';

import ICON_SIZE from '@/constant/icon-size-color';

export default function Collapse({ title, subMenu }: Props) {
  return (
    <Accordion>
      <>
        <AccordionButton className='flex h-[51px] w-full items-center justify-between rounded-md bg-[#F9FAFC] p-[10px]'>
          <Text size={SIZE_ENUM.MD} medium className='px-2 text-[#76767C]'>
            {title}
          </Text>
          <Container
            center
            className='h-[30px] w-[30px] rounded-md border border-solid border-[#EEF0F4] bg-white'
          >
            <IoIosArrowDown
              color='#878F9B'
              size={ICON_SIZE.sm}
              className='transform transition ease-in ui-open:rotate-180'
            />
          </Container>
        </AccordionButton>
        <AccordionPanel className='w-full bg-[#F9FAFC] px-4 pb-2'>
          {subMenu?.map((item) => {
            return (
              <Text key={item.id} size={SIZE_ENUM.SM} className='p-2 text-[#76767C]'>
                <Link href={item.url} prefetch={false} rel={item.rel} target={item.target}>
                  {item.title}
                </Link>
              </Text>
            );
          })}
        </AccordionPanel>
      </>
    </Accordion>
  );
}
