import Link from 'next/link';
import { IoClose } from 'react-icons/io5';

import cn from '@/lib/clsxm';

import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import Props from '@/components/layout/components/[default]/header/@shared/topbar/text/type';

const buttonSizeClasses = {
  lg: 'w-[120px] md:w-[200px]',
  sm: 'w-[100px] md:w-[150px]',
};
const buttonColorClasses = {
  orange: 'bg-[#cb8000] text-[#fff]',
  red: 'bg-[#ff5c62] text-[#fff]',
  blue: 'bg-[#65c0f0] text-[#fff]',
  green: 'bg-[#51b555] text-[#fff]',
  graylight: 'bg-[#e6e6e6] text-[#808080]',
  'graylight-blue': 'bg-[#e6e6e6] text-[#808080]',
  grayLight2: 'bg-[#f2f2f2] text-[#808080]',
  graydark: 'bg-[#bababa] text-[#808080]',
  white: 'bg-[#fff] text-[#b3b3b3]',
  'gray-8': 'bg-[#e6e6e6] text-[#808080]',
};
export default function Text({
  buttonText,
  buttonColor,
  mainContent,
  showCloseButton,
  backgroundColor = '#fff',
  contentColor,
  backgroundImage,
  buttonSize,
  oncloseHandler,
  externalLink,
  buttonUrl,
}: Props) {
  const TopBarButton = ({ children }) => {
    return (
      <Button
        className={cn(
          buttonSizeClasses[buttonSize],
          buttonColorClasses[buttonColor],
          'md:left-0-6 absolute left-4 h-10 px-2 py-2 text-xs md:h-12 md:px-7 md:py-4 md:text-base',
        )}
        onClick={() =>
          externalLink ? window.open(buttonUrl, '_blank', 'noopener,noreferrer') : null
        }
      >
        {externalLink ? children : <Link href={buttonUrl} prefetch={false}>{children}</Link>}
      </Button>
    );
  };
  return (
    <Container
      center
      className={cn('h-[70px] px-4 md:px-6')}
      style={{
        backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      {showCloseButton && (
        <Button
          className={cn(
            'absolute right-4 h-8 w-8 md:right-6 md:h-10 md:w-10',
            buttonColorClasses[buttonColor],
          )}
          onClick={(e) => {
            oncloseHandler();
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <IoClose size={20} />
        </Button>
      )}

      <Container className='line-clamp-1 w-full max-w-[1279px] px-10 text-sm md:px-16 md:text-lg'>
        <Container
          onClick={() =>
            externalLink ? window.open(buttonUrl, '_blank', 'noopener,noreferrer') : null
          }
          style={{ color: contentColor }}
          dangerouslySetInnerHTML={{ __html: mainContent }}
          className='max-w-[220px] md:max-w-full'
        />
      </Container>
      <TopBarButton>{buttonText}</TopBarButton>
    </Container>
  );
}
