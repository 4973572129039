import React from 'react';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';

import Props from '@/components/layout/components/[default]/header/@shared/profile/progressbar/type';

const CircularProgressBar = ({ percentage }:Props) => {
  const size = 40;
  const strokeWidth = 4;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <Container center>
      <svg className='rotate-[-90deg]' width={size} height={size}>
        <circle
          className='text-[#FFE6C0]'
          strokeWidth={strokeWidth}
          stroke='currentColor'
          fill='transparent'
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          className='text-[#EB8800]'
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap='round'
          stroke='currentColor'
          fill='transparent'
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{ transition: 'stroke-dashoffset 0.5s ease' }}
        />
      </svg>
      <Text className='absolute pt-1 text-[#FFB616]' size={SIZE_ENUM.XS} bold>
        {percentage}%
      </Text>
    </Container>
  );
};

export default CircularProgressBar;
