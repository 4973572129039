'use client';
import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Card, { CardContent, CardHeader } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Rating from '@/components/app/product/components/side-bar/rate-action/rating';

import localization from '@/constant/localization';

export default function Item({ data, onChange }) {
  return (
    <Card noShadow noBorder className='gap-1 pb-3'>
      <CardHeader>
        <Text size={SIZE_ENUM.SM} className='line-clamp-1 text-[#424244]'>
          {data.productTitle}
        </Text>
      </CardHeader>
      <CardContent center className='justify-between gap-3 p-0'>
        <Container center className='items-center justify-end gap-2 '>
          <XImage
            className='rounded-full'
            src={data.storeLogo?.publicLink}
            alt={data.storeLogo?.title}
            width={28}
            height={28}
          />
          <Text className='line-clamp-1 text-[#878F9B]' size={SIZE_ENUM.XS}>
            {data.storeName}
          </Text>
        </Container>
        <Container center className='justify-start gap-1'>
          <Text className='mt-[6px] whitespace-nowrap text-[#878F9B]' size={SIZE_ENUM.XS}>
            {localization.yourRate} :
          </Text>
          <Rating
            onSelect={(val) => onChange(data.productId, val)}
            totalStars={5}
            size='text-2xl md:text-xl'
          />
        </Container>
      </CardContent>
    </Card>
  );
}
