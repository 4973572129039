import ImageModel from '@/models/image.model';

export default interface SideInModel {
  title: string;
  link: string;
  isExternalLink: boolean;
  startedAt: number;
  expiredAt: number;
  width: string;
  type: SIDE_IN_TYPES;
  slogan: string;
  banner: ImageModel;
  desktopBanner: ImageModel;
  button: SideInButton;
  discountCode: string;
}

export enum SIDE_IN_TYPES {
  TEXT_WITH_BUTTON = 'text-button',
  TEXT_WITH_DISCOUNT = 'text-discount',
  IMAGE = 'image',
  COUNTER = 'counter',
}

export type SideInButton = {
  title: string;
  link: string;
  color: string;
};
