import Container from '@/components/@base/container';
import Collapse from '@/components/layout/components/[default]/footer/menu/mobile/collapse';

import { DATA_CY_FOOTER_MENU } from '@/constant/cypress';
import Props from '@/components/layout/components/[default]/footer/menu/mobile/type';

export default function MobileMenu({ menu }:Props) {
  return (
    <Container
      data-cy={DATA_CY_FOOTER_MENU}
      center
      className='w-full flex-col gap-[10px] pt-6 md:hidden'
    >
      {menu?.map((item, index) => {
        return <Collapse key={index} title={item.title} subMenu={item.children} />;
      })}
    </Container>
  );
}
