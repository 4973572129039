import dynamic from 'next/dynamic';
import { FC } from 'react';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';

const iconMap = {
  SlLayers: dynamic(() => import('~/images/icons/SlLayers')),
  PiHeart: dynamic(() => import('~/images/icons/piHeart')),
  CiDiscount1: dynamic(() => import('~/images/icons/CiDiscount1')),
  CiPlug1: dynamic(() => import('~/images/icons/CiPlug1')),
  IoIosCodeWorking: dynamic(() => import('~/images/icons/IoIosCodeWorking')),
  PiFileHtmlLight: dynamic(() => import('~/images/icons/PiFileHtmlLight')),
};

interface DynamicComponentProps {
  iconName: keyof typeof iconMap | string;
  size?: number;
  color?: string;
}

const DynamicComponent: FC<DynamicComponentProps> = ({
  iconName,
  size = ICON_SIZE.lg,
  color = ICON_COLOR.gray,
}) => {
  const Icon = iconMap[iconName];

  if (!Icon) {
    return <div>Component not found</div>;
  }

  return <Icon size={size} color={color} />;
};

export default DynamicComponent;
