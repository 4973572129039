import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import List, { ListItem } from '@/components/@base/list';

import { ICON_COLOR } from '@/constant/icon-size-color';

import Props from '@/components/app/cart/components/list/item/features/type';

export default function Features({ data, visible }: Props) {
  const [rendered, setRendered] = useState(visible);

  useEffect(() => {
    if (visible) {
      setRendered(true);
    }
  }, [visible]);

  const handleAnimationEnd = () => {
    if (!visible) {
      setRendered(false);
    }
  };

  return (
    <Container
      className={cn(
        'w-full transform overflow-hidden transition-all duration-300',
        visible
          ? 'max-h-full translate-y-0 opacity-100'
          : 'max-h-0 translate-y-[-20px] opacity-0',
        !rendered && 'hidden',
      )}
      onAnimationEnd={handleAnimationEnd}
    >
      <List className='mb-5 mt-3 flex w-full flex-col gap-2 rounded-lg bg-[#F7F8F9] p-6'>
        {data?.map((item) => (
          <ListItem className='flex gap-2 text-[13px] text-[#76767C]' key={item}>
            <FaCheck color={ICON_COLOR.purple} className='mt-1 size-[10px] min-w-[12px]' />
            {item}
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
