import Link from 'next/link';

import Props from '@/components/app/side-in/components/banner/type';

export default function Banner({ link, image }: Props) {
  return (
    <Link href={link} target='_blank' className='h-full w-full' prefetch={false}>
      <img src={image.publicLink} alt={image.title || 'side-in'} />
    </Link>
  );
}
