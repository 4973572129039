import Link from 'next/link';

import XImage from '@/components/@base/x-image';
import Props from '@/components/layout/components/[default]/header/@shared/topbar/banner/type';

export default function Banner({ desktopBanner, mobileBanner ,buttonUrl}: Props) {

  return (
    <>
          <Link href={buttonUrl} prefetch={false} target="_blank" rel="noopener noreferrer">
            <XImage
              src={desktopBanner.publicLink}
              alt={desktopBanner.name}
              fill
              className='hidden !object-cover md:block'
            /></Link>
          <Link href={buttonUrl} prefetch={false}  target="_blank" rel="noopener noreferrer">
            <XImage
              src={mobileBanner.publicLink}
              alt={mobileBanner.name}
              fill
              className='!object-cover md:hidden'
            />
          </Link>
        </>
  );
}
