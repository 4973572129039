'use client';
import { useEffect, useState } from 'react';

import { getCookie, setCookie } from '@/lib/cookies';

import Container from '@/components/@base/container';
import Banner from '@/components/layout/components/[default]/header/@shared/topbar/banner';
import Text from '@/components/layout/components/[default]/header/@shared/topbar/text';
import { useGetTopbar } from '@/components/layout/services/hooks';

export default function Topbar() {
  const { data, isLoading, error } = useGetTopbar();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (data?.id) {
      const topbarHasClosed = getCookie(`topbar-${data.id}`);
      setHide(!!topbarHasClosed);
    }
  }, [data]);

  const today = Math.floor(Date.now() / 1000);

  if (
    isLoading ||
    error ||
    !data ||
    !data.show ||
    today < data.startedAt ||
    today > data.expirationAt ||
    hide
  ) {
    return null;
  }

  const closeTopbarHandler = () => {
    setHide(true);
    setCookie(`topbar-${data.id}`, 'true', { path: '/', maxAge: 86400 });
  };

  return (
    <Container center className='relative h-[70px] w-full [&>*]:w-full' id='zhktopbar'>
      {data.banner ? (
        <Banner {...data} />
      ) : (
        <Text {...data} oncloseHandler={closeTopbarHandler} />
      )}
    </Container>
  );
}
