import Link from 'next/link';

import { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import List, { ListItem } from '@/components/@base/list';
import { Text } from '@/components/@base/typography';
import Props from '@/components/layout/components/[default]/header/desktop/categories/dropdown/item/child/type';
import { useGetProfile } from '@/components/layout/services/hooks';

import MenuItemModel from '@/models/menu-item.model';

export default function ChildItem({ items, parentsLength }: Props) {
  const chunkArray = (array: MenuItemModel[], size: number): MenuItemModel[][] => {
    let result: MenuItemModel[][] = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };
  const { profile } = useGetProfile();
  const menuEventHandler = (item) => {
    eventTracker(profile?.mobile, 'category_click', new Date().toISOString(), {
      transcript: item.title,
    });
  };
  return items?.children?.map((item) => {
    const itemGroups = chunkArray(item.children, 5);
    return itemGroups?.map((group, idx) => (
      <Container key={idx} className='w-full max-w-[270px] rounded-[10px] pr-[20px] pt-[15px]'>
        {idx === 0 && item.title && (
          <Text
            size={SIZE_ENUM.MD}
            className='pb-4 text-[#424244] hover:text-[#ff9606]'
            onClick={() => menuEventHandler(item)}
          >
            <Link href={item.url} prefetch={false} target={item.target} rel={item.rel}>
              {item.title}
            </Link>
          </Text>
        )}
        <List>
          {group?.map((itm, idx) => (
            <ListItem key={idx} onClick={() => menuEventHandler(itm)}>
              <Container>
                <Text size={SIZE_ENUM.SM} className='pb-3 text-[#76767C] hover:text-[#ff9606]'>
                  <Link href={itm.url} prefetch={false} target={itm.target} rel={itm.rel}>
                    {itm.title}
                  </Link>
                </Text>
              </Container>
            </ListItem>
          ))}
        </List>
      </Container>
    ));
  });
}
