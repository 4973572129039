import axiosWithHeaders from '@/lib/axios-instance';
import { APIUrlGenerator } from '@/lib/helper';

import { API_ROUTES } from '@/constant/routes';
import SideInModel from '@/models/side-in.model';

export async function getSideIn() {
  const {
    data: { payload },
  }: { data: { payload: SideInModel } } = await axiosWithHeaders.get(
    APIUrlGenerator(API_ROUTES.GET_SIDE_IN),
  );
  return payload;
}
