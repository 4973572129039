import { AxiosError } from 'axios';
import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { HiOutlineEnvelope } from 'react-icons/hi2';

import { APIErrorResponseGenerator } from '@/lib/helper';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { CONTAINER_TAG_TYPE } from '@/components/@base/container/type';
import Input from '@/components/@base/input/input';
import { INPUT_TYPES } from '@/components/@base/input/input/type';
import { showToast } from '@/components/@base/toast/show-toast';
import XImage from '@/components/@base/x-image';
import { usePostNewsletter } from '@/components/layout/services/hooks';
import IconTextCombo from '@/components/shared/components/icon-text-combo';

import { DATA_CY_FOOTER_NEWSLETTER } from '@/constant/cypress';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';
import {PiEnvelopeOpenLight} from "react-icons/pi";

const { layout: layoutLocalization } = pageLevelLocalization;
export default function Newsletter() {
  const [value, setValue] = useState('');
  const { mutate, isPending } = usePostNewsletter();
  const postNewsletterHandler = () => {
    mutate(value, {
      onSuccess: (res) => {
        showToast({ message: res.messages[0], type: 'success' });
      },
      onError: (e) => {
        if (e instanceof AxiosError) showToast(APIErrorResponseGenerator(e));
      },
    });
  };
  return (
    <Container
      data-cy={DATA_CY_FOOTER_NEWSLETTER}
      className='mt-4 h-[159px] rounded-xl border-[3px] border-solid border-white bg-[linear-gradient(233.69deg,#FFDAA2_-5.92%,#FFF3E0_17.7%)] p-7 shadow-[0px_5px_25px_0px_rgba(255,107,1,0.08)] md:m-0'
    >
      <IconTextCombo
        Icon={
          <XImage
            src={imagesList.icons.zhkBrownSmLogo}
            width={22}
            height={22}
            alt={localization.zhaket}
          />
        }
        title={layoutLocalization.zhaketNewsLetter}
        textSize={SIZE_ENUM.LG}
        textClassName='text-[#544C45]'
      />
      <Container className='pt-6'>
        <Input
          placeholder={layoutLocalization.enterYourEmail}
          containerClassName='bg-white h-12 w-full hover:w-[calc(100%+4px)] hover:ring-1 hover:ring-[#878F9B] transition-all duration-300'
          className='border-0 p-1'
          value={value}
          type={INPUT_TYPES.EMAIL}
          onChange={(e) => setValue(e.target.value)}
          Icon={() => {
            return (
              <Button
                disabled={isPending}
                className='h-10 w-10'
                onClick={postNewsletterHandler}
              >
                <FaArrowLeft color='#FFFFFF' size={15} />
              </Button>
            );
          }}
          PlaceholderIcon={() => {
            return (
              <Container type={CONTAINER_TAG_TYPE.SPAN} className='pr-[13px]'>
                <PiEnvelopeOpenLight color='#878F9B' size={20} />
              </Container>
            );
          }}
        />
      </Container>
    </Container>
  );
}
