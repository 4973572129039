'use client';
import Container from '@/components/@base/container';
import Contact from '@/components/layout/components/[default]/footer/contact';
import DesktopMenu from '@/components/layout/components/[default]/footer/menu/desktop';
import MobileMenu from '@/components/layout/components/[default]/footer/menu/mobile';
import Newsletter from '@/components/layout/components/[default]/footer/newsletter';
import { useGetMenuList } from '@/components/layout/services/hooks';

export default function Menu() {
  const { data } = useGetMenuList();
  if (!data) {
    return null;
  }
  return (
    <>
      <MobileMenu menu={data.footer} />
      <Container className='justify-between md:flex md:pt-[50px]'>
        <DesktopMenu menu={data.footer} />
        <Container className='flex flex-col md:w-[377px]'>
          <Newsletter />
          <Contact />
        </Container>
      </Container>
    </>
  );
}
