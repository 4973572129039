import cn from '@/lib/clsxm';

import { AccordionButton } from '@/components/@base/accordion';
import Container from '@/components/@base/container';
import XImage from '@/components/@base/x-image';
import Props from '@/components/shared/components/stylish-disclosure/button/type';

import {
  DATA_CY_STYLISH_ACCORDION_BUTTON,
  DATA_CY_STYLISH_ACCORDION_BUTTON_MINUS_ICON,
  DATA_CY_STYLISH_ACCORDION_BUTTON_PLUS_ICON,
} from '@/constant/cypress';
import imagesList from '@/constant/images-list';

export default function Button({ children, className }: Props) {
  return (
    <AccordionButton
      className={cn('flex w-full items-center justify-between', className)}
      data-cy={DATA_CY_STYLISH_ACCORDION_BUTTON}
    >
      {({ open }) => (
        <>
          <Container center className='justify-start gap-[10px]'>
            {children({ open })}
          </Container>
          <Container className='max-h-4 max-w-4'>
            {open ? (
              <XImage
                src={imagesList.icons.minusIcon}
                alt='minus'
                data-cy={DATA_CY_STYLISH_ACCORDION_BUTTON_MINUS_ICON}
                width={15}
              />
            ) : (
              <XImage
                src={imagesList.icons.plusIcon}
                alt='plus'
                data-cy={DATA_CY_STYLISH_ACCORDION_BUTTON_PLUS_ICON}
                height={15}
              />
            )}
          </Container>
        </>
      )}
    </AccordionButton>
  );
}
