import { PopoverPanel, Transition } from '@headlessui/react';

import Props from '@/components/@base/popup/panel/type';

export default function Panel({ children, ...props }: Props) {
  return (
    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <PopoverPanel {...props}>{children}</PopoverPanel>
    </Transition>
  );
}

