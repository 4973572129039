'use client';
import { useQueryClient } from '@tanstack/react-query';
import { FaRegTrashCan } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';

import cn from '@/lib/clsxm';
import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import Loading from '@/components/@base/loading';
import { LOADING_COLORS } from '@/components/@base/loading/type';
import { showToast } from '@/components/@base/toast/show-toast';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Actions from '@/components/app/cart/components/list/item/remove/actions';
import Props from '@/components/app/cart/components/list/item/remove/type';
import { useDeleteFromCart } from '@/components/app/cart/services/hooks';
import { useGetProfile } from '@/components/layout/services/hooks';

import useCommonModalStore from '@/store/common-modal-store';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';
import { GET_CART } from '@/constant/query-key';

const { cart: cartLocalization } = pageLevelLocalization;

export default function Remove({ product, hideModal }: Props) {
  const { setShow } = useCommonModalStore();
  const { mutate, isPending } = useDeleteFromCart();
  const queryClient = useQueryClient();
  const { profile } = useGetProfile();

  const handleRemoveModal = () => {
    setShow(true, {
      DialogPanelProps: {
        className: 'rounded-xl w-full !max-w-[370px] overflow-hidden !p-0',
      },
      Body() {
        return (
          <Container center className='relative flex-col p-10'>
            <XImage
              fill
              className='!left-0 !top-0 !h-[160px] w-full'
              src={imagesList.backgrounds.removeCartBG}
              alt={localization.cart}
            />
            <Container
              center
              className='h-[54px] w-[54px] rounded-full border-[2px] border-white bg-[linear-gradient(247.65deg,#FFA488_-35.57%,#FF693B_100%)]'
            >
              <FaRegTrashCan size={ICON_SIZE.md} color={ICON_COLOR.white} />
            </Container>
            <Text bold size={SIZE_ENUM.LG} className='pt-5 text-[#424244]'>
              {cartLocalization.doYouWantToRemoveTheProduct}
            </Text>
            <Text className='pt-3 text-[13px] text-[#76767C]'>
              {cartLocalization.accessToProductFileForLifeTime}
            </Text>
            <Actions product={product} />
          </Container>
        );
      },
    });
  };
  const handleRemoveFromCart = () => {
    mutate(product.id, {
      onSuccess: ({ payload, messages }) => {
        queryClient.setQueryData([GET_CART], payload);
        eventTracker(profile?.mobile, 'remove_from_basket', new Date().toISOString(), {
          productId: product.id,
        });
        handleGA4Event(
          'remove_from_cart',
          [
            {
              item_id: product.id,
              item_name: product.title,
              price: product.title,
              quantity: 1,
              index: 0,
              affiliation: product?.store?.slug,
              discount: product.discount,
            },
          ],
          {
            currency: 'USD',
            value: product.price,
          },
        );

        setShow(false);
        showToast({ message: messages[0] });
      },
    });
  };
  if (hideModal && isPending) {
    return (
      <Container className='w-[36px] pl-2'>
        <Loading color={LOADING_COLORS.ORANGE} />
      </Container>
    );
  }
  return (
    <Button
      center
      onClick={hideModal ? handleRemoveFromCart : handleRemoveModal}
      className={cn('ml-[17px] h-[23px] w-[23px] rounded-lg bg-[#FFF1ED] p-0')}
    >
      <IoClose color={ICON_COLOR.red} size={ICON_SIZE.xs} />
    </Button>
  );
}
