import Link from 'next/link';

import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import DiscountBox from '@/components/app/side-in/components/banner-with-text/discount-box';
import Props from '@/components/app/side-in/components/banner-with-text/type';

import { SIDE_IN_TYPES } from '@/models/side-in.model';

export default function BannerWithText({
  type,
  title,
  slogan,
  banner,
  link,
  desktopBanner,
  button,
  discountCode,
}: Props) {
  const discount = discountCode || 'avgdsgrsdgbc'; //TODO: remove this it's a sample

  return (
    <Container className='flex h-full flex-col'>
      <XImage
        width={342}
        height={145}
        src={desktopBanner?.publicLink || banner.publicLink}
        alt={title}
        className='max-h-[145px] rounded-[10px] object-cover object-top'
      />
      <Container className='flex h-full flex-col items-center justify-around py-4'>
        <Container center className='flex-col'>
          <Text className='line-clamp-1 text-xl font-bold text-[#424244]'>{title}</Text>
          <Text className='line-clamp-2 text-[13px] text-[#76767C]'>{slogan}</Text>
        </Container>
        {type === SIDE_IN_TYPES.TEXT_WITH_BUTTON ? (
          <Link href={button.link || link}>
            <Button
              style={{ backgroundColor: `${button.color}` }}
              className='h-[52px] min-w-[140px] rounded-md text-[15px] font-bold text-[#FFFFFF] transition duration-300 hover:shadow-xl'
            >
              {button.title}
            </Button>
          </Link>
        ) : (
          discount && <DiscountBox code={discount} />
        )}
      </Container>
    </Container>
  );
}
