'use client';
import { IoSearchOutline } from 'react-icons/io5';

import Button from '@/components/@base/button';
import { Input } from '@/components/@base/input';
import SearchModal from '@/components/shared/components/search/search-modal';

import useCommonModalStore from '@/store/common-modal-store';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

const { layout: layoutLocalization } = pageLevelLocalization;

export default function Search({ isSmallScreen }: { isSmallScreen?: boolean }) {
  const { setShow } = useCommonModalStore();

  const openSearchModal = () => {
    setShow(true, {
      Body() {
        return <SearchModal />;
      },
      DialogPanelProps: { className: 'w-full max-w-[680px] rounded-md !p-0' },
    });
  };

  if (isSmallScreen) {
    return (
      <Button
        className='h-10 w-10 bg-white shadow-[0px_4px_8px_0px_rgba(153,126,86,0.08)]'
        onClick={() => openSearchModal()}
      >
        <IoSearchOutline color={ICON_COLOR.gray} size={ICON_SIZE.md} />
      </Button>
    );
  }

  return (
    <>
      <Input
        containerClassName='bg-[#F9FAFC] w-[275px] px-4 h-12 shadow-none'
        className='border-0 bg-[#F9FAFC] p-0'
        Icon={() => (
          <IoSearchOutline
            className='cursor-pointer'
            color={ICON_COLOR.gray}
            size={ICON_SIZE.lg}
            onClick={() => openSearchModal()}
          />
        )}
        onClick={() => openSearchModal()}
        readOnly={true}
        placeholder={layoutLocalization.searchAtZhaket}
      />
    </>
  );
}
