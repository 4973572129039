import cn from '@/lib/clsxm';

import { AccordionPanel } from '@/components/@base/accordion';
import Props from '@/components/shared/components/stylish-disclosure/panel/type';

import { DATA_CY_STYLISH_ACCORDION_PANEL } from '@/constant/cypress';

export default function Panel({ children, className }: Props) {
  return (
    <AccordionPanel
      className={cn(
        'flex flex-col gap-2 rounded-[10px] bg-white p-6 shadow-[0px_5px_15px_0px_rgba(73,75,84,0.06)]',
        className,
      )}
      data-cy={DATA_CY_STYLISH_ACCORDION_PANEL}
    >
      {children}
    </AccordionPanel>
  );
}
