import { Disclosure } from '@headlessui/react';

import Button from '@/components/@base/accordion/button';
import Panel from '@/components/@base/accordion/panel';
import Props from '@/components/@base/accordion/type';

export default function Accordion({ children, ...props }: Props) {
  return <Disclosure {...props}>{children}</Disclosure>;
}

export { Button as AccordionButton, Panel as AccordionPanel };
