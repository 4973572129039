'use client';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';

import cn from '@/lib/clsxm';
import { getCookie, setCookie } from '@/lib/cookies';
import { useClientSmallScreen } from '@/lib/user-agent/client';

import Container from '@/components/@base/container';
import Banner from '@/components/app/side-in/components/banner';
import BannerWithText from '@/components/app/side-in/components/banner-with-text';
import { useGetSideIn } from '@/components/app/side-in/services/hooks';

import { SIDE_IN_TYPES } from '@/models/side-in.model';

const SIDE_IN_COOKIE_KEY = 'hide-sidein';
export default function SideIn() {
  const [showSideIn, setShowSideIn] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const isSmallScreen = useClientSmallScreen();
  const path = usePathname();
  const isProductPage = /^\/web\/[^/]+(\/(comments|support))?$/.test(path);

  const { data } = useGetSideIn();

  useEffect(() => {
    // Trigger the animation after the component mounts
    if (window && data && data.link && data.banner?.publicLink) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000);

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [data]);

  if (!data || !data?.link || !data?.banner?.publicLink) {
    return null;
  }

  const shouldHideSideIn = getCookie(SIDE_IN_COOKIE_KEY);
  if (shouldHideSideIn) {
    return null;
  }

  const handleCloseSideIn = () => {
    // Calculate the expiration date for 24 hours
    const maxAgeSeconds = 24 * 60 * 60;
    setCookie(SIDE_IN_COOKIE_KEY, true, { maxAge: maxAgeSeconds });
    setShowSideIn(false);
  };

  return showSideIn ? (
    <Container
      id='zhksidein'
      className={cn(
        'fixed -right-[300px] bottom-[40px] z-[60] h-auto shadow-[0px_20px_60px_-15px_#00000026] transition-all duration-700 ease-in-out md:bottom-[50px]',
        isProductPage && 'bottom-[100px]',
        data.type !== SIDE_IN_TYPES.IMAGE &&
          !isSmallScreen &&
          '-right-[370px] h-[340px] !w-[363px] rounded-md bg-[#FFFFFF] p-2.5',
        isVisible && 'right-5',
      )}
      style={
        isSmallScreen
          ? { width: `${data?.width}px`, height: 'auto' }
          : data.type === SIDE_IN_TYPES.IMAGE
            ? { width: '300px' }
            : {}
      }
    >
      {data.type === SIDE_IN_TYPES.IMAGE || isSmallScreen ? (
        <Banner link={data.link || '#'} image={data?.banner} />
      ) : (
        <BannerWithText {...data} />
      )}

      <Container
        className='absolute -top-7 right-0 cursor-pointer rounded-sm bg-[#edf1f580] p-1 transition duration-300 hover:bg-[#E5E8EB] md:-top-4'
        onClick={handleCloseSideIn}
      >
        <IoCloseSharp fill='white' size={20} />
      </Container>
    </Container>
  ) : null;
}
