'use client';
import { FiInfo } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import { Props } from '@/components/@base/toast/type';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';

const contextClass = {
  success: { bg: 'bg-[#81CF33]', btn: 'bg-[rgba(129,207,51,0.78)]' },
  error: { bg: 'bg-[#FF6437]', btn: 'bg-[#ff6f45]' },
  info: { bg: 'bg-[#6097F3]', btn: 'bg-[#66676b]' },
  warning: { bg: 'bg-[#FF9606]', btn: 'bg-[#ff9d17]' },
  default: { bg: 'bg-[#6097F3]', btn: 'bg-[#6e9ef4]' },
  dark: { bg: 'bg-[#5B5C60]', btn: 'bg-[#66676b]' },
};

export default function Toast({ bottomPosition, ...props }: Props) {
  return (
    <ToastContainer
      position={bottomPosition ? 'bottom-center' : 'top-center'}
      autoClose={3000}
      newestOnTop={false}
      closeOnClick
      rtl
      stacked={bottomPosition}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      toastClassName={(context) =>
        contextClass[context?.type || 'default'].bg +
        ` relative flex px-[18px] text-[15px] mb-4 font-medium min-h-[63px] w-full items-center rounded-md justify-between overflow-hidden cursor-pointer ${bottomPosition && 'md:w-[450px]'}`
      }
      closeButton={(props) => {
        return (
          <Container
            center
            className={cn(
              'h-10 w-10 rounded-full',
              contextClass[props?.type || 'default'].btn,
            )}
          >
            <IoClose color={ICON_COLOR.white} size={ICON_SIZE.md} />
          </Container>
        );
      }}
      icon={<FiInfo color={ICON_COLOR.white} size={ICON_SIZE.lg} />}
      theme='colored'
      {...props}
    />
  );
}

export { toast };
