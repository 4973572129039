import ParentItem from '@/components/layout/components/[default]/header/desktop/cascading-menu/dropdown/item/parent';
import Container from '@/components/@base/container';

import { DATTA_CY_MENU_ITEM_BUTTON } from '@/constant/cypress';

import Props from '@/components/layout/components/[default]/header/desktop/cascading-menu/dropdown/type';

export default function Dropdown({items ,show }: Props) {
  
  return (
    <Container
      data-cy={DATTA_CY_MENU_ITEM_BUTTON}
      center
      className={`absolute -right-2 top-12 z-50 w-[300px] items-start justify-start rounded-[10px] bg-white  opacity-0 shadow-[0px_20px_60px_-15px_rgba(0,0,0,0.15)] transition ease-in-out ${show ? 'visible opacity-100' : 'invisible'}`}
    >
      <ParentItem
        items={items}
      />
    </Container>
  );
}
